@import 'styles/_colors.scss';
@import 'styles/_dimensions.scss';
@import 'styles/_table.scss';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;600&display=swap');

* {
  font-family: 'Montserrat', sans-serif !important;
  box-sizing: border-box;
}

.MuiButton-contained.jofemarButtonSpacing {
  margin-right: 10px;
}

html,
body {
  -webkit-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

button.MuiButton-containedPrimary {
  background-color: $primary_light;
}

button.MuiButton-containedPrimary:hover {
  background-color: $primary_main;
}

.fw-200 {
  font-weight: 200;
}

.text-center {
  text-align: center;
}

#root {
  height: 100%;
}

.App {
  display: flex;
  height: 100%;
}

.p2 {
  font-size: 16px;
  font-weight: 200;
}

.gray {
  color: $gray;
}

.ai-center {
  align-items: center;
}

.flex {
  display: flex;
}

.flex-center-justify {
  display: flex;
  justify-content: center;
}

.flex-center-align {
  display: flex;
  align-items: center;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.jcsb {
  justify-content: space-between;
}

.jcse {
  justify-content: space-evenly;
}

.jcc {
  justify-content: center;
}

.screen {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%
}

.full-width {
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.shadow {
  box-shadow: 3px 3px 5px 1px rgba(144, 144, 144, 0.74);
}

#privateRoutesWrapper {
  width: 100%;
}

#Header {
  background-color: $lightgreen;
  border-bottom: 2px solid $applered;
}

#sidebar {
  background-color: $lightgreen;
  position: absolute;
  left: 0;
  top: 0;

  svg {
    fill: white;
  }
}

#tableContainer {
  background: white;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  margin-left: 85px;
  z-index: 1;
  width: calc(100% - 95px);
}

.MuiAvatar-root:hover {
  cursor: pointer;
}

.lockers-container {
  flex-direction: column;
}

.block.D2 {
  max-height: 410px;
}

// .block.D2 .lockers-container {
//   max-height: 410px;
// }

// fade css

// .blocks-container:after {
//   content  : "";
//   position : fixed;
//   z-index  : 1;
//   top   : 0;
//   left     : 90vw;
//   pointer-events   : none;
//   background-image : linear-gradient(to right, 
//                     rgba(255,255,255, 0), 
//                     rgba(255,255,255, 1) 90%);
//   width    : 100px;
//   height   : 90%;
// }

/////////////
// Dimensions
/////////////

.m0 {
  margin: 0;
}

.mb0 {
  margin-bottom: 0;
}

.mt0 {
  margin-top: 0;
}

.mt1 {
  margin-top: 1rem;
}

.mr1 {
  margin-right: 1rem;
}

.ml1 {
  margin-left: 1rem !important;
}

//////////////
// Components
//////////////

#dynamicTable.ProductCategories {
  width: 500px;
}

#dynamicTable.TransactionList {
  white-space: pre-wrap;
}

#dynamicTable.TransactionList tbody td {
  vertical-align: top;
}

/////////////
// Overrides
/////////////

.MuiButton-contained.green-btn {
  background-color: $lightgreen !important;
  color: white;
}

.MuiButton-contained.inactive-btn {
  background-color: #006600 !important;
  color: grey;
}

.MuiButton-contained.red-btn {
  background-color: $applered !important;
  color: white;
}

.MuiSelect-select {
  padding: 10.5px 32px 10.5px 14px !important;
}

.block-header {
  .MuiCheckbox-root {
    padding: 5px !important;
  }

  svg {
    font-size: 3rem !important;
  }

  p {
    font-size: 17px;
  }
}


///////////
/// PRINT
///////////

.print-bottom {
  margin-top: 40px;
  text-align: center;
}

.print-outer {
  max-height: 600px;
  overflow-y: auto,
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {

  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}

@page {
  size: auto;
  margin: 20mm;
}