/* margins */
.m-bt-med {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.m-t-1 {
  margin-top: 1rem;
}
.m-t-2 {
  margin-top: 2rem;
}
.m-b-med {
  margin-bottom: 1rem;
}
.m-b-sm {
  margin-bottom: 0.5rem;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-05 {
  margin-bottom: 0.5rem !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mt-05 {
  margin-top: 0.5rem !important;
}
.mt-1 {
  margin-top: 1rem;
}
.mt-2 {
  margin-top: 2rem;
}
.mt-2_5 {
  margin-top: 2.5rem;
}
.ml-1 {
  margin-left: 1rem;
}
.ml-2 {
  margin-left: 2rem;
}
.ml-4 {
  margin-left: 4rem;
}
.mr-1 {
  margin-right: 1rem!important;
}
.m0 {
  margin: 0;
}