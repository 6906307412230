@import 'styles/_colors.scss';

#dynamicTable{
  margin-top: 10px;

  svg{
    cursor: pointer;
  }  
  table{
    border: 1px solid $lightgreen;
  }
  thead{
    background: $lightgreen;

    th{
      color:white;
      border-left: 1px solid white;
      padding: 6px 8px;
      text-align: center;
    }
    
  }
  tbody{
    td{
      border: 1px solid $lightgray;
      padding: 6px 8px;
      text-align: center;
    }
    td:first-child{
      width: 55px;
    }
  }
  .add-btn{
    display: flex;
    justify-content: center;

    svg:hover{
      color:darken(white,15%);
    }
  }
  .edit-btn, .delete-btn{
    svg:hover{
      color:lighten($black,25%);
    }
  }
  .filter-container{
    padding: 5px;
    span{font-weight: 400;}
    input{margin-left: 5px;}
  }
}